import React from 'react';
import { Provider } from 'mobx-react';
import siteSettingsStore from './src/stores/site-settings-store';

import addToOrderStore from './src/stores/add-to-order-store';
import checkoutStore from './src/stores/checkout-store';

import RootElement from './src/components/root-element';

const ProvideStores = ({ element }) => (
  <RootElement>
    <Provider
      siteSettings={new siteSettingsStore()}
      checkoutStore={new checkoutStore()}
      addToOrderStore={new addToOrderStore()}>
      {element}
    </Provider>
  </RootElement>
);

export default ProvideStores;
