/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import fonts from '../web/src/styles/fonts.scss';
import provideStores from './provide-stores';

export const wrapRootElement = provideStores;
