import { makeObservable, observable, action, toJS } from 'mobx';

class checkoutStore {
  checkoutCartTemp = {};
  currentCheckoutStep = '';

  checkoutSteps = {
    hasShippingAddress: false,
    requireShippingMethod: false,
    hasSignedWaiver: false,
    requireSignedWaiver: false,
    hasShippingMethod: false,
    requierPaymentToday: false,
  };

  constructor() {
    makeObservable(this, {
      checkoutSteps: observable,
      currentCheckoutStep: observable,
      setCheckoutSteps: action,
      updateCheckoutSteps: action,
      determineCheckoutStep: action,
      setCheckoutStepLogin: action,
      setCheckoutStepShippingAddress: action,
      setCheckoutStepShippingMethod: action,
      setCheckoutStepWaiver: action,
      setCheckoutStepSubmitNoPayment: action,
      setCheckoutStepSubmitPayment: action,
    });
  }

  setCheckoutStepLogin() {
    this.currentCheckoutStep = 'stepLogin';
  }

  setCheckoutStepShippingAddress() {
    this.currentCheckoutStep = 'stepShippingAddress';
  }

  setCheckoutStepShippingMethod() {
    this.currentCheckoutStep = 'stepShippingMethod';
  }

  setCheckoutStepWaiver() {
    this.currentCheckoutStep = 'stepWaiver';
  }

  setCheckoutStepSubmitNoPayment() {
    this.currentCheckoutStep = 'stepSubmitNoPayment';
  }

  setCheckoutStepSubmitPayment() {
    this.currentCheckoutStep = 'stepSubmitPayment';
  }

  /**
   * Determine which checkout step is the current step
   * @returns
   */
  determineCheckoutStep() {
    // Shipping address?
    if (!this.checkoutSteps.hasShippingAddress) {
      this.setCheckoutStepShippingAddress();
      return;
    }

    // Already Has shipping address

    // Shipping method?
    if (this.checkoutSteps.requireShippingMethod) {
      if (!this.checkoutSteps.hasShippingMethod) {
        this.setCheckoutStepShippingMethod();
        return;
      }
    }

    // Already has shipping method if required

    // Waiver
    if (this.checkoutSteps.requireSignedWaiver) {
      if (!this.checkoutSteps.hasSignedWaiver) {
        this.setCheckoutStepWaiver();
        return;
      }
    }

    // Already has waiver if required

    // Show submit (either payment or no payment)
    if (this.checkoutSteps.requierPaymentToday) {
      this.setCheckoutStepSubmitPayment();
    } else {
      this.setCheckoutStepSubmitNoPayment();
    }
  }

  updateCheckoutSteps(cart) {
    if (!cart) {
      return;
    }

    if (cart.isMto) {
      this.setCheckoutSteps('requireShippingMethod', false);
      this.setCheckoutSteps('requierPaymentToday', false);
    } else {
      this.setCheckoutSteps('requireShippingMethod', true);
      this.setCheckoutSteps('requierPaymentToday', true);
    }

    if (cart.shippingAddress) {
      this.setCheckoutSteps('hasShippingAddress', true);
    } else {
      this.setCheckoutSteps('hasShippingAddress', false);
    }

    // FIXME: Will be set by customerShippingOptions and customerShippingChoice
    if (cart.shipping && cart.shipping != 0) {
      this.setCheckoutSteps('hasShippingMethod', true);
    } else {
      this.setCheckoutSteps('hasShippingMethod', false);
    }

    if (cart.requireSignature) {
      this.setCheckoutSteps('requireSignedWaiver', true);
    } else {
      this.setCheckoutSteps('requireSignedWaiver', false);
    }

    if (cart.signed) {
      this.setCheckoutSteps('hasSignedWaiver', true);
    }

    // Determine the current checkout stpe
    this.determineCheckoutStep();
  }

  setCheckoutSteps(field, value) {
    this.checkoutSteps[field] = value;
  }
}

export default checkoutStore;
