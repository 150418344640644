/**
 * Client-lib for Ladder's member API. All calls are made for an authenticated user. The auth info
 * must be in the global `window.ladderMemberCenterData`.
 *
 * When a validation error occurs while create/updating a resource, the returned promise will
 * reject with an instance of `ValidationError`.
 */

import { camelizeKeys } from 'humps';
import { getAuthToken } from '../lib/cookie';
import axios from 'axios';
import removeDraftsFromTitle from '../utils/removeDraftsFromTitle';
import { ChildDataType, MetaDataType } from 'src/components/hooks/useAddToCart';
import { UpdateUserPayload } from 'src/components/hooks/useUpdateUser';
import { SaveShippingAddressPaylaod } from 'src/components/hooks/useShippingAddress';
import { SaveShippingOptionPayload } from 'src/components/hooks/useSaveShippingOption';
import { SignUpPayload } from 'src/components/hooks/useSignUp';
import { ContactFormPayload } from 'src/components/hooks/useContactForm';
import { BitpayPaymentPayload } from 'src/components/hooks/useBitpayPayment';
import { GetArchiveImageParams } from 'src/components/hooks/useGetArchiveImage';
import { PasswordResetData } from 'src/components/hooks/usePasswordReset';
import { GetProductionTimeByCategoryParams } from 'src/components/hooks/useGetProductionTimeByCategory';

// class ValidationError extends Error {
//   constructor(validationErrors) {
//     super(`ValidationError: ${JSON.stringify(validationErrors)}`);

//     this.isValidationError = true;
//     this.validationErrors = validationErrors;
//   }
// }

type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
type Data = any;
type Params = Record<string, string>;

const axiosRequest = async <T>(
  method: Method,
  path: string,
  data: Data = {},
  params?: Params
): Promise<T> => {
  const url = `${process.env.GATSBY_API_URL}${path}`;
  const authToken = getAuthToken();

  const options = {
    method,
    withCredentials: true,
    headers: {
      'content-type': 'application/json; charset=utf-8',
      ...(authToken && { Authorization: `Bearer ${authToken}` }),
    },
    params,
    ...(data && { data }),
  };

  const resp = await axios(url, options);
  if (resp.status === 200) {
    return camelizeKeys(resp.data) as T;
  } else if (resp.status === 422) {
    return camelizeKeys(resp.data).then((validationErrors: unknown) => {
      Promise.reject(validationErrors);
    });
  } else {
    return Promise.reject(resp);
  }
};

export const getUser = () => axiosRequest('GET', `/api/user`);

export const getOrder = () => axiosRequest('GET', `/api/order`);

export const getOrderHistory = () =>
  axiosRequest('GET', `/api/order/get-orders`);

export const getDealerOrderHistory = () =>
  axiosRequest('GET', `/api/order/get-orders?dealer_orders=true`);

// FIXME: Refactor to react query
export const updateUser = (data: UpdateUserPayload) =>
  axiosRequest('PUT', `/api/user/edit`, data);

export const getAddresses = () =>
  axiosRequest('GET', `/api/user/get-addresses/`);

export const getDealerCustomers = () =>
  axiosRequest('GET', `/api/order/get-dealer-customers`);

export const getDealerCommission = () =>
  axiosRequest('GET', `/api/order/dealer-comission`);

export const getOrderByPaymentCode = (paymentCode: string) =>
  axiosRequest('GET', `/api/order/get-order-with-payment-link/${paymentCode}`);

// FIXME: React query refactor
export const getProductById = (productId: string) => {
  return axiosRequest(
    'GET',
    `/api/product/${removeDraftsFromTitle(productId)}`
  );
};

// FIXME: React query refactor
export const getOrderById = (orderId?: string) =>
  axiosRequest('GET', `/api/order/${orderId}`);

export const addToCart = (
  sku: string,
  qty = 1,
  svgIcon = '',
  metadata: MetaDataType[] = [],
  childData: ChildDataType[] = []
) => {
  return axiosRequest('POST', `/api/order/add`, {
    sku,
    qty: Number(qty),
    // svg_icon: svgIcon,
    meta: metadata,
    child: childData,
  });
};

export const applyCommissionAsPayment = (balance: number, notes?: string) =>
  axiosRequest('POST', `/api/payment/commission-balance`, {
    amount: balance,
    notes,
  });

export const applyCommissionForCustomer = (
  orderId: string,
  balance: number,
  notes?: string
) =>
  axiosRequest('POST', `/api/payment/commission-balance/${orderId}`, {
    amount: balance,
    notes,
  });

// FIXME: React query refactor
export const doCheckoutPayment = async (
  paymentMethodNonce: string,
  paymentType: string,
  notes?: string
) => {
  return await axiosRequest('POST', '/api/payment/braintree', {
    payment_method_nonce: paymentMethodNonce,
    payment_type: paymentType,
    notes,
  });
};

export const saveShippingAddress = (data: SaveShippingAddressPaylaod) =>
  axiosRequest('POST', `/api/order/save-shipping-address`, data);

// FIXME: Remove company option on shipping options endpoint
export const getShippingOptions = () =>
  axiosRequest('GET', `/api/order/shipping-options`);

// FIXME: Need to save correct selected shipping option
export const saveShippingOption = (data: SaveShippingOptionPayload) =>
  axiosRequest('POST', `/api/order/save-shipping-option`, {
    carrier: data?.carrier,
    service_code: data?.serviceCode,
    service_name: data?.serviceName,
  });

export const saveWaiver = (name: string) =>
  axiosRequest('POST', `/api/order/create-order-signature`, {
    checkbox: 'on',
    name,
  });

export const submitNoPayment = (notes?: string, optIn: boolean = false) =>
  axiosRequest('POST', `/api/order/submit`, {
    notes,
    optIn,
  });

export const applyDiscountCode = (orderId: string, discountCode: string) =>
  axiosRequest('POST', `/api/order/${orderId}/add-discount`, {
    discount_codes: discountCode,
  });

export const removeDiscount = (orderId: string) =>
  axiosRequest('POST', `/api/order/${orderId}/remove-discount`);

export const login = (email: string, password: string, rememberMe = false) =>
  axiosRequest('POST', `/api/login`, {
    email,
    password,
    remember: rememberMe,
  });

export const logoutModel = () => axiosRequest('POST', `/api/user/logout`, {});

// FIXME: React query refactor
export const loginFromAdminModel = (token: string) =>
  axiosRequest('POST', `/api/password/verify-login/${token}`, {});

export const signUp = (registerData: SignUpPayload) =>
  axiosRequest('POST', `/api/user/create`, registerData);

export const loginGuest = (email: string, rememberMe = false) =>
  axiosRequest('POST', `/api/login-guest`, {
    email,
    remember: rememberMe,
  });

export const updateQuantity = (itemId: string, qty: number) =>
  axiosRequest('POST', `/api/order/item/${itemId}/update-qty`, { qty: qty });

// FIXME: React query refactor
export const contactForm = (body: ContactFormPayload) =>
  axiosRequest('POST', `/api/contact/`, body);

export const contactCoach = async (payload: {
  email: string;
  message: string;
  name: string;
  coach?: {
    slug: {
      current: string;
    };
  };
}) => {
  const slug = payload.coach?.slug.current;
  delete payload.coach;

  return axiosRequest('POST', `/api/coach/contact-coaches/${slug}`, payload);
};

export const orderMergeNotified = () =>
  axiosRequest('POST', '/api/order/set-merged-notified');

type BitpayPaymentResponse = {
  url: string;
};

export const createBitPayPayment = ({ notes }: BitpayPaymentPayload) =>
  axiosRequest<BitpayPaymentResponse>('POST', '/api/payment/bitpay', { notes });

export const getArchiveImage = (params: GetArchiveImageParams) =>
  axiosRequest(
    'GET',
    '/api/product/archive-image',
    {},
    {
      category: params.category,
      type: params.type,
    }
  );

export const passwordReset = ({
  confirmPassword,
  password,
  token,
}: PasswordResetData) =>
  axiosRequest('POST', `/api/password/usetoken/${token}`, {
    confirmPassword,
    password,
  });

type GetProductionTimeResponse = {
  data: { weeks: number; monthString: string; furthestItem: string };
};

export const getProductionTime = () => {
  return axiosRequest<GetProductionTimeResponse>(
    'GET',
    '/api/order/production-time'
  );
};

type GetProductionTimeByIdResponse = {
  status: string;
  data: {
    weeks: number;
    monthString: string;
  };
};

export const getProductionTimeByCategory = ({
  category,
}: GetProductionTimeByCategoryParams) => {
  return axiosRequest<GetProductionTimeByIdResponse>(
    'GET',
    `/api/product/production-time/category/${category}`
  );
};
