import { action, makeObservable, observable } from 'mobx';

class siteSettingsStore {
  displayHelpModal = false;
  displayWarningModal = false;
  displayWarningModalContent = null;
  displayNonBinaryModal = false;
  displaySuperCustomModal = false;
  displaySuperDuperCustomModal = false;
  displaySuperCustomDesignerModal = false;
  displayBaseSystemModal = false;
  displayCartMergeModal = false;

  videos = [];
  videoIndex = null;
  videoUrl = null;

  images = [];
  imageTitles = [];
  imageIndex = null;
  imageUrl = null;
  wysiwygImages = [];

  content = null;
  contentType = null;

  productDetail = null;

  constructor() {
    makeObservable(this, {
      displayHelpModal: observable,
      displayWarningModal: observable,
      displayWarningModalContent: observable,
      displayNonBinaryModal: observable,
      displaySuperCustomModal: observable,
      displaySuperDuperCustomModal: observable,
      displaySuperCustomDesignerModal: observable,
      displayBaseSystemModal: observable,
      displayCartMergeModal: observable,
      videos: observable,
      videoIndex: observable,
      videoUrl: observable,
      images: observable,
      imageTitles: observable,
      wysiwygImages: observable,
      imageIndex: observable,
      imageUrl: observable,
      content: observable,
      contentType: observable,
      addImageToWwysiwygImages: action,
      setDisplayBaseSystemModal: action,
      productDetail: observable,
      setProductDetail: action,
      setDisplayHelpModal: action,
      setDisplayWarningModal: action,
      setDisplayNonBinaryModal: action,
      setDisplaySuperCustomModal: action,
      setDisplaySuperDuperCustomModal: action,
      setDisplaySuperCustomDesignerModal: action,
      setDisplayCartMergeModal: action,
      setVideoUrl: action,
      setVideoList: action,
      setVideoIndex: action,
      closeVideo: action,
      setImageUrl: action,
      setImageList: action,
      setImageTitleList: action,
      setImageIndex: action,
      closeImage: action,
      setContentModal: action,
      closeContentModal: action,
    });
  }

  setDisplayHelpModal(val) {
    this.displayHelpModal = val;
  }
  setDisplayWarningModal(val, content) {
    this.displayWarningModal = val;
    this.displayWarningModalContent = content;
  }
  setDisplayNonBinaryModal(val) {
    this.displayNonBinaryModal = val;
  }
  setDisplayBaseSystemModal(val) {
    this.displayBaseSystemModal = val;
  }
  setDisplaySuperCustomModal(val) {
    this.displaySuperCustomModal = val;
  }
  setDisplaySuperDuperCustomModal(val) {
    this.displaySuperDuperCustomModal = val;
  }
  setDisplaySuperCustomDesignerModal(val) {
    this.displaySuperCustomDesignerModal = val;
  }
  setDisplayCartMergeModal(val) {
    this.displayCartMergeModal = val;
  }

  // Video Modal
  setVideoUrl(videoUrl) {
    this.videoUrl = videoUrl;
  }

  setVideoList(videos, currentIndex) {
    this.videos = videos;
    this.videoIndex = currentIndex;
  }

  setVideoIndex(videoIndex) {
    this.videoIndex = videoIndex;
  }

  addImageToWwysiwygImages(value) {
    this.wysiwygImages.push(value);
  }

  closeVideo() {
    this.videoUrl = null;
  }

  // Image Modal
  setImageUrl(imageUrl) {
    this.imageUrl = imageUrl;
  }

  setImageList(images, currentIndex) {
    this.images = images;
    this.imageIndex = currentIndex;
  }

  setImageTitleList(titles) {
    this.imageTitles = titles;
  }

  setImageIndex(imageIndex) {
    this.imageIndex = imageIndex;
  }

  closeImage() {
    this.imageUrl = null;
    this.imageTitles = [];
    this.images = [];
  }

  setContentModal(content, type) {
    this.content = content;
    this.contentType = type;
  }
  closeContentModal() {
    this.content = null;
    this.contentType = null;
  }

  setProductDetail(product) {
    this.productDetail = product;
  }
}

export default siteSettingsStore;
