exports.components = {
  "component---src-components-cart-cart-js": () => import("./../../../src/components/Cart/cart.js" /* webpackChunkName: "component---src-components-cart-cart-js" */),
  "component---src-components-checkout-checkout-js": () => import("./../../../src/components/Checkout/checkout.js" /* webpackChunkName: "component---src-components-checkout-checkout-js" */),
  "component---src-components-login-admin-login-admin-js": () => import("./../../../src/components/LoginAdmin/login-admin.js" /* webpackChunkName: "component---src-components-login-admin-login-admin-js" */),
  "component---src-components-order-order-confirmation-js": () => import("./../../../src/components/Order/order-confirmation.js" /* webpackChunkName: "component---src-components-order-order-confirmation-js" */),
  "component---src-components-password-reset-password-reset-js": () => import("./../../../src/components/PasswordReset/password-reset.js" /* webpackChunkName: "component---src-components-password-reset-password-reset-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-manual-index-js": () => import("./../../../src/pages/all-manual/index.js" /* webpackChunkName: "component---src-pages-all-manual-index-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-dealers-js": () => import("./../../../src/pages/dealers.js" /* webpackChunkName: "component---src-pages-dealers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-users-account-js": () => import("./../../../src/pages/users/account.js" /* webpackChunkName: "component---src-pages-users-account-js" */),
  "component---src-sections-learn-learn-detail-learn-detail-js": () => import("./../../../src/sections/Learn/LearnDetail/learn-detail.js" /* webpackChunkName: "component---src-sections-learn-learn-detail-learn-detail-js" */),
  "component---src-sections-learn-learn-search-learn-search-jsx": () => import("./../../../src/sections/Learn/LearnSearch/learn-search.jsx" /* webpackChunkName: "component---src-sections-learn-learn-search-learn-search-jsx" */),
  "component---src-sections-news-news-detail-news-detail-js": () => import("./../../../src/sections/News/NewsDetail/news-detail.js" /* webpackChunkName: "component---src-sections-news-news-detail-news-detail-js" */),
  "component---src-templates-landing-page-landing-page-js": () => import("./../../../src/templates/LandingPage/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-landing-page-js" */),
  "component---src-templates-landing-page-order-page-js": () => import("./../../../src/templates/LandingPage/OrderPage.js" /* webpackChunkName: "component---src-templates-landing-page-order-page-js" */),
  "component---src-templates-landing-page-product-display-page-js": () => import("./../../../src/templates/LandingPage/ProductDisplayPage.js" /* webpackChunkName: "component---src-templates-landing-page-product-display-page-js" */),
  "component---src-templates-manual-js": () => import("./../../../src/templates/Manual.js" /* webpackChunkName: "component---src-templates-manual-js" */)
}

